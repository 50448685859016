import { Box, Button, Confetti, Header, Notification, P } from '@declarando/design_system';
import imageStep from './assets/Checklist-inicial.svg';
import { useContext, useEffect } from 'react';
import { OnboardingContext } from '../../Onboarding';
import { publishFinalizarConfiguracionEvent } from '../../../services/events';

export const StepRevisada = () => {
    const { setCheckCurrentStep, siguintePaso } = useContext(OnboardingContext);
    useEffect(() => {
        setCheckCurrentStep();
    }, []);

    const finalizarConfiguracionClick = () => {
        new Confetti('confetti-canvas').runEmojis();
        siguintePaso();
        publishFinalizarConfiguracionEvent();
    };

    return (
        <>
            <Box px={'sm'} mx={'auto'} py={'xxl'} backgroundColor={'white'} borderRadius={'md'} minHeight={600}>
                <Box maxWidth={588} mx={'auto'}>
                    <Box alignCenter>
                        <Box mb="sm">
                            <img src={imageStep} alt={'Finalizado'} />
                        </Box>
                        <Header as={'h2'}>¡Todo listo! Vamos a por los primeros pasos</Header>
                        <P mt={'xs'}>
                            Tu perfil se ha configurado correctamente. A continuación, te mostraremos una lista de
                            tareas para que avancemos juntos en tus primeros pasos.
                        </P>

                        <Box maxWidth={570} mx={'auto'} my={'lg'} display={{ _: 'grid', tablet: 'block' }}>
                            <Button
                                icon={'arrow-right'}
                                iconPosition={'right'}
                                onClick={finalizarConfiguracionClick}
                                label={'Empezar con mis primeros pasos'}
                            />
                        </Box>
                    </Box>
                    <Box display={'flex'}>
                        <Notification
                            tone={'info'}
                            message={
                                <>
                                    Si no ves la lista de tareas, la encontrarás siempre en el apartado <i>Tareas</i>{' '}
                                    del chat
                                </>
                            }
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};
